/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, SeparateLine, Menu, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Výberové konania na pracovné miesta"}>
        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0,0,0,1)"}} name={"1k2xo2czmsf"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--hvr5 js-anim  --anim3" anim={"3"}>
              
              <Title className="title-box title-box--left fs--20" content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"/\">Tribečské múzeum v Topoľčanoch</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0,0,0,1)"}} name={"jqn5icwqqt"}>
        </SeparateLine>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"rgba(245,166,35,1)"}} name={"08dzrkjmu0el"}>
        </SeparateLine>


        <Column className="--menu css-1xtlaww --parallax" style={{"paddingTop":0,"paddingBottom":0}} menu={true} name={"repkopet198"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=3000x_.JPG);
    }
  
    `}>
          
          <Menu className="--full" fullscreen={true}>
            
            <ColumnWrapper className="menu-logo-box js-anim  --anim3 --anim-s8" anim={"3"} animS={"8"}>
              
              <Image style={{"maxWidth":100}} alt={"Logo"} src={"https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=660x_.png"} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=860x_.png 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox style={{"marginTop":0,"paddingTop":0}}>
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--001" href={"/"} target={""} content={"Hlavná stránka"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/kniznica"} target={""} content={"Knižnica"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/projekty"} target={""} content={"Projekty"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/podujatia"} target={""} content={"Podujatia"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/#vystavy"} target={""} content={"Výstavy"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--001" href={"/muzeum-online#vystavy"} target={""} content={"Múzeum online"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-1xtlaww --parallax" style={{"marginTop":0,"paddingTop":120,"paddingBottom":107}} name={"5nyx315dxr"} layout={"l1"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--hvr2 --shape4 js-anim  --anim2" style={{"maxWidth":930,"backgroundColor":"var(--white)"}} anim={"2"}>
              
              <Button className="btn-box ff--1" style={{"backgroundColor":"var(--white)","maxWidth":978}} use={"file"} href={"https://cdn.swbpg.com/d/7746/technik-vystav-spravy-a-udrzby-muzea.docx"} target={"_blank"} content={"<span style=\"font-weight: bold;\">TECHNIK SPRÁVY A ÚDRŽBY MÚZEA</span><br>"} document={"0b6d78fab682420fbdd1fed4d8090e78"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0}} name={"7r15xu02ci"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255,255,255,1)"}} name={"xkhphd26gt"}>
        </SeparateLine>


        <Column className="css-1xtlaww --parallax" style={{"paddingTop":272,"paddingBottom":0}} name={"vtbxk4zm98"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/15ba5a5c259e4784b3ea7c5f0039cc6a_s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--2 --full" columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="--left">
              
              <Image className="--left" style={{"maxWidth":168}} src={"https://cdn.swbpg.com/t/7746/0b6390fe717b4e12bacc85c98c8b63c1_s=660x_.png"} sizes="(max-width: 639px) 100vw, 50vw" srcSet={"https://cdn.swbpg.com/t/7746/0b6390fe717b4e12bacc85c98c8b63c1_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/0b6390fe717b4e12bacc85c98c8b63c1_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/0b6390fe717b4e12bacc85c98c8b63c1_s=860x_.png 860w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":8.53125,"backgroundColor":"rgba(0,0,0,1)"}} name={"19r1mtqhy1m"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left ff--3 fs--14" style={{"marginTop":2.3125,"paddingTop":0}} content={"<span style=\"color: rgb(155, 155, 155);\">© 2023 Tribečské múzeum v Topoľčanoch, všetky práva vyhradené</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}